import { BASE_API } from '@/api/v1';
import axios from 'axios';

class SignUpController {
  async postNewUser(userData) {
    try {
      return await axios
        .post(BASE_API + 'register', userData)
    } catch (error) {
    }
  }
}

export default SignUpController;