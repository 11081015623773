<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" @click="$router.push('/')">
        <img src="../assets/images/logo/logoTimer.png" style="width: 28px; height: 28px" />
        <h2 class="brand-text text-primary" style="margin-left: 0.5rem">Tuesday</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Sign Up to Tuesday! 📝
          </b-card-title>
          <b-card-text class="mb-1">
            Please sign-up your new account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- name -->
              <b-form-group label="Name" label-for="Signup-name">
                <ValidationProvider name="Name" rules="required" v-slot="{ errors, valid }">
                  <b-form-input id="signup-name" v-model="name" :state="errors.length > 0 ? false : valid ? true : null"
                    name="signup-name" placeholder="John Example" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="signup-email">
                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
                  <b-form-input id="signup-email" v-model="email" :state="errors.length > 0 ? false : valid ? true : null"
                    name="signup-email" placeholder="john@example.com" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <ValidationProvider rules="required|min:6" name="Password" v-slot="{ errors, valid }">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="password"
                      :state="errors.length > 0 ? false : valid ? true : null" class="form-control-merge"
                      :type="passwordFieldType" name="login-password" placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="confirm-password">Confirm Password</label>
                </div>
                <ValidationProvider rules="required" name="Confirm Password" v-slot="{ errors, valid }">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="confirm-password" v-model="confirmPassword" :state="errors.length > 0
                        ? false
                        : valid && confirmPassword === password
                          ? true
                          : null
                      " class="form-control-merge" :type="passwordFieldType" name="confirm-password"
                      placeholder="············" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0] ||
                    (valid && confirmPassword !== password
                      ? "Passwords do not match."
                      : "")
                  }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button @click="postNewUser()" type="submit" variant="primary" block>
                Sign up
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link @click="$router.push('/login')">
              <span>&nbsp;Log-In</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>
  
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SignUpController from "../controllers/SignUpController";
import { log } from "@/utils/log";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      name: "",
      password: "",
      email: "",
      confirmPassword: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    async postNewUser() {
      this.$refs.loginValidation.validate().then(async (success) => {
        try {
          const confirmPassword = this.confirmPassword;
          const password = this.password;
          if (success && confirmPassword === password) {
            const userData = {
              name: this.name,
              email: this.email,
              password: this.password,
            };

            const signUpController = new SignUpController();
            const response = await signUpController.postNewUser(userData);
            if (response.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Registration Successful",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$router.push("/login");
            }
          } else if (confirmPassword !== password) {
            this.$toast.error({
              component: ToastificationContent,
              props: {
                title: "Passwords do not match!",
                icon: "EditIcon",
                variant: "error",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please fill in all fields",
                icon: "EditIcon",
                variant: "warning",
              },
            });
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "User Already Exists!",
              icon: "DefaultIcon",
              variant: "info",
            },
          });
          log(error);
          this.$router.push("");
        }
      });
    },
  },
};
</script>
  
  
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
  