var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{staticStyle:{"width":"28px","height":"28px"},attrs:{"src":require("../assets/images/logo/logoTimer.png")}}),_c('h2',{staticClass:"brand-text text-primary",staticStyle:{"margin-left":"0.5rem"}},[_vm._v("Tuesday")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Sign Up to Tuesday! 📝 ")]),_c('b-card-text',{staticClass:"mb-1"},[_vm._v(" Please sign-up your new account and start the adventure ")]),_c('validation-observer',{ref:"loginValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"Signup-name"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"signup-name","state":errors.length > 0 ? false : valid ? true : null,"name":"signup-name","placeholder":"John Example"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"signup-email"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"id":"signup-email","state":errors.length > 0 ? false : valid ? true : null,"name":"signup-email","placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")])]),_c('ValidationProvider',{attrs:{"rules":"required|min:6","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : valid ? true : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password")])]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","state":errors.length > 0
                      ? false
                      : valid && _vm.confirmPassword === _vm.password
                        ? true
                        : null,"type":_vm.passwordFieldType,"name":"confirm-password","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || (valid && _vm.confirmPassword !== _vm.password ? "Passwords do not match." : "")))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":function($event){return _vm.postNewUser()}}},[_vm._v(" Sign up ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_c('span',[_vm._v(" Log-In")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }